import * as PIXI from 'pixi.js';

import Composer from './Composer';
import Viewport from 'pixi-viewport';

class Visual {
  constructor(options) {
    Object.assign(this, options);
    // Animation
    this.lastTime = 0;
    this.running = false;

    // there we go
    this.init();

    // bind
    this.animate = this.animate.bind(this);
  }

  init() {
    this.initApp();
    this.initViewport();
    this.initResize();
  }

  initApp() {
    let canvas = document.createElement('canvas');
    canvas.style.width = window.innerWidth + 'px';
    canvas.style.height = window.innerHeight + 'px';

    // create app
    this.app = new PIXI.Application(window.innerWidth, window.innerHeight, {
      backgroundColor: 0xfff6d5,
      resolution: window.devicePixelRatio,
      autoDensity: window.devicePixelRatio !== 1,
      antialias: true,
      view: canvas,
    });
    document.getElementById('visual').appendChild(this.app.view);

    // composer
    this.composer = new Composer(this);
  }

  initViewport() {
    // Viewport
    this.viewport = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: window.innerWidth,
      worldHeight: window.innerHeight,
      interaction: this.app.renderer.plugins.interaction, // the interaction module is important for wheel() to work properly when renderer.view is placed or scaled
      threshold: 5,
      divWheel: this.app.view,
    });

    this.viewport.clamp({ direction: 'all', underflow: 'center' });
    this.viewport.clampZoom(this.getClampZoom());

    // add the viewport to the stage
    this.app.stage.addChild(this.viewport);
    this.app.stage.y += 10;

    // make touch/mouse move only fire when over the object
    //app.renderer.plugins.interaction.moveWhenInside = true;

    // activate plugins
    this.viewport
      .drag()
      .pinch()
      .wheel({ smooth: 3 })
      .decelerate();

    // update country scaling on zoom
    this.viewport.on('zoomed', () => {
      this.composer.rescale(this.viewport.scale.x);
    });
  }

  getClampZoom() {
    return {
      minWidth: 0.33 * window.innerWidth,
      minHeight: 0.33 * window.innerHeight,
      maxWidth: window.innerWidth,
      maxHeight: window.innerHeight,
    };
  }

  initResize() {
    // Resize
    window.addEventListener('resize', () => {
      // resize canvas
      this.app.view.style.width = window.innerWidth + 'px';
      this.app.view.style.height = window.innerHeight + 'px';

      // Resize the renderer
      this.app.renderer.resize(window.innerWidth, window.innerHeight);

      // Resize viewport
      this.viewport.resize(
        window.innerWidth,
        window.innerHeight,
        window.innerWidth,
        window.innerHeight,
      );

      this.viewport.clampZoom(this.getClampZoom());

      this.composer.container.x = window.innerWidth / 2;
      this.composer.container.y = window.innerHeight / 2;

      // update composer
      this.composer.resize();
    });
  }

  // load data
  load(data) {
    this.data = data;
    this.composer.load(data);
    this.viewport.addChild(this.composer.container);
    this.composer.container.x = window.innerWidth / 2;
    this.composer.container.y = window.innerHeight / 2;
  }

  setCountry(iso) {
    this.composer.countries.forEach(country => {
      country.setActive(country.iso === iso);
    });
  }

  // Set layout
  setLayout(id, options) {
    //viewport.zoomPercent(1, true);
    this.viewport.zoom(window.innerWidth);
    this.composer.setLayout(id, options);
  }

  animate(elapsed) {
    const dt = Math.min(elapsed - this.lastTime, 0.01);
    this.lastTime = elapsed;

    this.composer.animate(dt, elapsed);

    if (this.running) {
      window.requestAnimationFrame(this.animate);
    }
  }

  start() {
    this.running = true;
    window.requestAnimationFrame(this.animate);
  }

  stop() {
    this.running = false;
  }
}

export default Visual;
