import Country from './Country';
import * as PIXI from 'pixi.js';
import AnimatedValue from '../../util/AnimatedValue';
//import RegionColors from './RegionColors';
import Color from 'color';
import GeoLayout from './GeoLayout';
import DataLayout from './DataLayout';
import DNALayout from './DNALayout';
import RandomLayout from './RandomLayout';
import CountryLayout from './CountryLayout';

const lineColor = 0xe4ca67; //0xffd94c;

class Composer {
	constructor(visual) {
		// root visual
		this.visual = visual;
		// countries
		this.countries = [];

		this.minSize = 0.15;
		this.padding = 30;

		this.props = {
			envAlpha: new AnimatedValue(1, 0),
			gridAlpha: new AnimatedValue(1, 0),
		};

		// layouts
		const layoutOptions = {
			composer: this,
		};

		this.layouts = {
			data: new DataLayout(layoutOptions),
			dna: new DNALayout(layoutOptions),
			geo: new GeoLayout(layoutOptions),
			random: new RandomLayout(layoutOptions),
			country: new CountryLayout(layoutOptions),
		};

		this._layout = null;
		this._layoutId = 'data';

		this.lastHoverCountry = null;

		this._sizeVar = 0;

		this.initContainer();
	}

	resize() {
		// remove old from container
		this.container.removeChild(this.env);
		this.container.removeChild(this.dataHolder);

		// init Env
		this.initEnv();

		// restore dataHolder
		this.container.addChild(this.dataHolder);

		// update data
		this._layout.reapply();
	}

	initContainer() {
		this.container = new PIXI.Container();
		this.initEnv();

		// dataholder, contains the countries data
		this.dataHolder = new PIXI.Container();
		this.container.addChild(this.dataHolder);
	}

	initEnv() {
		this.env = new PIXI.Container();
		this.container.addChild(this.env);

		this.initGrid();

		this.initAxis();
	}

	initAxis() {
		const w = this.getWidth();
		const h = this.getHeight();

		//border
		this.border = new PIXI.Graphics();
		this.border.lineStyle(4, lineColor, 0.2);
		this.border.drawRoundedRect(
			-w / 2 - this.padding,
			-h / 2 - this.padding,
			w + this.padding * 2,
			h + this.padding * 2,
			20,
		);
		this.env.addChild(this.border);

		// const size = 2;
		// // xAxis
		// this.xAxis = new PIXI.Graphics();
		// this.xAxis.beginFill(0x000000, 0.46);
		// this.xAxis.drawRect(
		// 	-w / 2 - this.padding,
		// 	h / 2 + this.padding,
		// 	w + this.padding * 2,
		// 	size,
		// );
		// this.env.addChild(this.xAxis);

		// // yAxis
		// this.yAxis = new PIXI.Graphics();
		// this.yAxis.beginFill(0x000000, 0.6);
		// this.yAxis.drawRect(
		// 	-w / 2 - this.padding,
		// 	-h / 2 - this.padding,
		// 	size,
		// 	h + this.padding * 2,
		// );
		// this.env.addChild(this.yAxis);
	}

	initGrid() {
		// container background
		this.grid = new PIXI.Graphics();
		this.grid.lineStyle(2, lineColor, 0.15);

		// left
		const dx = (this.getWidth() + this.padding * 2) / 3;
		let hw = this.getWidth() / 2 + this.padding;
		const dy = (this.getHeight() + this.padding * 2) / 3;
		let hh = this.getHeight() / 2 + this.padding;

		// prettier-ignore
		const lines = [
			// left-mid-right
			//-hw, -hh, -hw, hh,
		 	-hw+dx, -hh, -hw+dx, hh,
		 	hw-dx, -hh, hw-dx, hh,
		 	//hw, -hh, hw, hh,
		 	// top-mid-bottom
		 	//-hw, hh, hw, hh,
		 	-hw, hh-dy, hw, hh-dy,
		 	-hw, -hh+dy, hw, -hh+dy,
		 	//-hw, -hh, hw, -hh,
		 ];

		for (let i = 0, len = lines.length; i <= len; i += 4) {
			this.grid.moveTo(lines[i], lines[i + 1]);
			this.grid.lineTo(lines[i + 2], lines[i + 3]);
		}

		// move a little bit to the inside of the grid
		// on mobile devices
		let mobile = window.innerWidth < 576;
		if (mobile) {
			hw -= this.padding;
			hh -= this.padding;
		} else {
			hw += 10;
			hh += 10;
		}

		// zero
		this.grid.lineStyle(3, lineColor, 0.4);
		this.grid.drawEllipse(-hw - 10, hh + 10, 6, 8);

		// plus right
		if (!mobile) {
			hw -= 30;
		}
		// vert
		this.grid.moveTo(hw + 10, hh + 2);
		this.grid.lineTo(hw + 10, hh + 18);
		// hori
		this.grid.moveTo(hw + 2, hh + 10);
		this.grid.lineTo(hw + 18, hh + 10);

		// plus lefttop
		if (!mobile) {
			hw += 30;
			hh -= 30;
		}
		// vert
		this.grid.moveTo(-hw - 10, -hh - 2);
		this.grid.lineTo(-hw - 10, -hh - 18);
		// hori
		this.grid.moveTo(-hw - 2, -hh - 10);
		this.grid.lineTo(-hw - 18, -hh - 10);

		this.env.addChild(this.grid);
	}

	load(data) {
		Object.values(data.countries).forEach(c => {
			c.color = Color(c.color).rgbNumber();

			// Circle interaction
			c.onCountryMouseOver = this.onCountryMouseOver;
			c.onCountryMouseOut = this.onCountryMouseOut;
			c.onCountryMouseDown = this.onCountryMouseDown;

			// DNA interaction
			c.onDNAMouseOver = this.onDNAMouseOver;
			c.onDNAMouseOut = this.onDNAMouseOut;
			c.onDNAMouseDown = this.onDNAMouseDown;

			let country = new Country(c);

			// add world icon to world
			if (country.iso === 'WRL') {
				country.addIcon('/images/icon-world.png');
			}
			this.countries.push(country);
			this.dataHolder.addChild(country.container);
		});
	}

	bringCountryToTop(country) {
		// not when there is an active country
		if (
			this._layoutId === 'country' &&
			country.iso !== this._layout.options.country
		) {
			return;
		}
		this.dataHolder.swapChildren(
			country.container,
			this.dataHolder.getChildAt(this.dataHolder.children.length - 1),
		);
	}

	// Country interaction
	onCountryMouseOver = country => {
		this.lastHoverCountry = country;

		this.visual.setState({ _hoverCountry: country.iso });

		this.bringCountryToTop(country);
	};

	onCountryMouseOut = country => {
		this.visual.setState({ _hoverCountry: '' });
	};

	onCountryMouseDown = (country, navigate = true) => {
		const state = {
			country: country.iso,
		};

		if (navigate) {
			state['layout'] = 'country';
			state['_hoverCountry'] = '';

			// sort of a hack
			// but if we are in country mode
			// on clicking the country again
			// navigate back to dna
			// so we have at least one way to return
			// clicking on other country (not!) goes to geo view
			if (this._layoutId === 'country') {
				if (this._layout.options.country === country.iso) {
					state['layout'] = 'dna';
				} else {
					//	state['layout'] = 'geo';
				}
			}
		}
		this.visual.setState(state);
	};

	// DNA interaction
	onDNAMouseOver = (country, index) => {
		this.visual.setState({
			_hoverValue: {
				name: this.visual.data.datakeys[index].name,
				data:
					country.data[index].e === 0
						? {
								a: country.data[index].a,
								r:
									Math.round(country.data[index].r * 100) +
									'%',
						  }
						: { a: '', r: 'no data' },

				country: country.iso,
			},
		});
	};

	onDNAMouseOut = (country, index) => {
		this.visual.setState({ _hoverValue: null });
	};

	onDNAMouseDown = (country, index, y = false) => {
		this.visual.setState({
			[y ? 'y' : 'x']: index,
			layout: 'data',
			country: country.iso,
			_hoverValue: null,
		});
	};

	setLayout(id, options) {
		this._layout = this.layouts[id];
		this._layoutId = id;
		this._layout.apply(options);
	}

	setSizeVar(n) {
		// correct for area/size
		// r = Math.sqrt(val/Math.PI)
		this._sizeVar = n;
		this.countries.forEach(c => {
			c.props.scale.set(this.minSize + Math.sqrt(c.data[n].r / Math.PI));
		});
	}

	setSize(n) {
		this.countries.forEach(c => {
			c.props.scale.set(this.minSize + n);
		});
	}

	setAlpha(n) {
		this.countries.forEach(c => {
			c.props.alpha.set(n);
		});
	}

	getMarginX() {
		return (window.innerWidth - this.getWidth()) / 2;
	}

	getWidth() {
		const width = window.innerWidth;
		let margin = width * 0.2;
		if (margin < 100) {
			margin = 100;
		}
		return width - margin;
	}

	getHeight() {
		const height = window.innerHeight;
		let margin = height * 0.2;
		if (margin < 200) {
			margin = 200;
		}
		return height - margin;
	}

	getFlyX() {
		const f = Math.random() > 0.5 ? 1 : -1;
		return f * (window.innerWidth / 2 + Math.random() * this.getWidth());
	}
	getFlyY() {
		const f = Math.random() > 0.5 ? 1 : -1;
		return f * (window.innerHeight / 2 + Math.random() * this.getHeight());
	}

	hideFlowers() {
		this.countries.forEach(c => {
			c.hideFlowers();
		});
	}

	enableCountryHover() {
		this.countries.forEach(c => {
			c.canHover = true;
		});
	}

	hideGrid() {
		this.props.gridAlpha.set(0);
	}

	showGrid() {
		this.props.gridAlpha.set(1);
	}

	animate(dt, elapsed) {
		// env
		this.env.alpha = this.props.envAlpha.update(dt);

		// grid
		this.grid.alpha = this.props.gridAlpha.update(dt);

		// countries
		this.countries.forEach(c => {
			c.animate(dt, elapsed);
		});
	}
	//s = 1...3
	rescale(s) {
		// on zoom in, make countries smaller
		// so clusters become more readable
		// disable on dna layout
		const dna = this._layout === this.layouts.dna;

		this.countries.forEach(c => {
			c.scalar = dna ? 1 : (2 + 1 / (s * s)) / 3;
			//c.flowers.alpha = Math.min(0.5, 0.5 * (s - 1));
		});
	}

	showDNA(delay) {
		this.countries.forEach(c => {
			c.showDNA(delay);
		});
	}

	hideDNA() {
		this.countries.forEach(c => {
			c.hideDNA();
		});
	}
}

export default Composer;
