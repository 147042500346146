import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div className="about-container">
        <div className="icon-close" onClick={this.props.onClick} />
        <div className="icon-logo" />

        <h2>Discover the DNA of a good government</h2>

        <p>
          This interactive visualization enables the exploration of the DNA of a
          good government. You can analyze and compare multiple indicators to
          investigate their influence on countries and the related behavior and
          performance of governments.
        </p>

        <p>
          Overviews help to recognize patterns in the data, which could be the
          starting point for an in depth analysis. Additionally, it seduces
          users to play with the data, share their findings with others and
          hopefully inspires people to make the world a better place.
        </p>

        <ul>
          <li>Pro-tip: Use the arrow keys to change viewing axis</li>
          <li>
            Works best in a recent{' '}
            <a
              href="https://chrome.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              browser
            </a>{' '}
            with Webgl support
          </li>
        </ul>

        <h2>Examples</h2>
        <ul>
          <li>
            <a href="#layout/dna/a/2/country/QAT" onClick={this.props.onClick}>
              Governments in some rich countries still have room for
              improvement.
            </a>
          </li>
          <li>
            <a href="#layout/data/x/24/y/32/z/28" onClick={this.props.onClick}>
              Free press could contribute to control of corruption.
            </a>
          </li>
          <li>
            <a
              href="#layout/data/country/CRI/x/8/y/5/z/8"
              onClick={this.props.onClick}
            >
              Money does buy Hapiness. But in Costa Rica, the people seem to be
              happy without.
            </a>
          </li>
          <li>
            <a
              href="#layout/data/country/SLE/x/10/y/11/z/8"
              onClick={this.props.onClick}
            >
              High health expenditure % of GDP are a burden on small and big
              countries. Some goverments are forces to act, e.g. by an epidemic.
            </a>
          </li>

          <li>
            <a
              href="#layout/data/country/FRA/x/28/y/16/z/8"
              onClick={this.props.onClick}
            >
              High government spending is by the grace of tax payers.
            </a>
          </li>
          <li>
            <a href="#layout/geo/country/WRL/z/13" onClick={this.props.onClick}>
              Good education is a luxury.
            </a>
          </li>
        </ul>
        <h2>Context</h2>
        <p>
          Created in context of the{' '}
          <a
            href="https://wdvp.worldgovernmentsummit.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            World Data Visualization Price
          </a>
          .
        </p>

        <a
          rel="noopener noreferrer"
          href="https://wdvp.worldgovernmentsummit.org"
          target="_blank"
        >
          <img
            className="wdvp-logo"
            src="/images/logo-wdvp.png"
            alt="World Data Visualization Price"
          />
        </a>

        <h2>Data</h2>

        <ul>
          <li>
            <a
              href="https://wdvp.worldgovernmentsummit.org/assets/downloads/WDVP-Datasets.xlsx"
              target="_blank"
              rel="noopener noreferrer"
            >
              WDVP Indicator Dataset
            </a>{' '}
          </li>
          <li>
            <a
              href="https://rsf.org/en/ranking"
              target="_blank"
              rel="noopener noreferrer"
            >
              World Press Freedom Index Score
            </a>
          </li>
          <li>
            <a href="#_dataKey/__">View all dataset details</a>
          </li>
          <li>Licenses can be found using the corresponding source urls.</li>
          <li>All rights to the datasets belong to their respective owners.</li>
        </ul>
        <p>
          &copy;{' '}
          <a
            href="https://www.sudox.nl?utm_source=govdna"
            target="_blank"
            rel="noopener noreferrer"
          >
            SUDOX
          </a>{' '}
          2019
        </p>
      </div>
    );
  }
}

export default About;
